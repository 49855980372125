<template>
  <div class="school-data-sarpras">
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          class="my-6"
          title="Data Sarpras"
          subtitle="Halaman untuk melihat sarpras sekolah"
          :total-items="totalItems"
          :headers="headers"
          :items="sarprass"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :no-add-button="true"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-image="showImage"
          @show-detail-sarpras="detailSarpras($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else>
    </SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialogSarpras"
      :width="1000"
      :hidden-actions="false"
      @close-modal="modalDialogSarpras = false"
      @close-button="modalDialogSarpras = false"
    >
      <template
        v-slot:body
      >
        <DataTablePagination
          :title="titleCard"
          subtitle="Halaman untuk melihat alat pada sarpras sekolah"
          :total-items="totalItems"
          :headers="headersTool"
          :items="sarprasTools"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :no-add-button="true"
          @page="page"
          @change-page="paginationHandler"
        >
        </DataTablePagination>
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="800"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="sarpras.photo"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiArrowLeft, mdiClose } from '@mdi/js'

export default {
  name: 'SchoolDataSarpras',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    ImageOverlay,
    SkeletonLoaderTable,
  },
  data() {
    return {
      sarprass: [],
      sarprasTools: [],
      student: {},
      icons: { mdiArrowLeft, mdiClose },
      search: '',
      modalCertificate: false,
      modalDialogSarpras: false,
      imageOverlay: false,
      overlay: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      sarpras: {},
      sarprasTool: {},
      sarpras_uuid: '',
      service: 'sarpras',
      headers: [
        { text: '#', value: 'index' },
        { text: 'Jenis', value: 'sarprasType' },
        { text: 'Nama', value: 'name' },
        { text: 'Foto', value: 'photo' },
        { text: 'Kondisi', value: 'sarprasCondition' },
        { text: '', value: 'detail-sarpras' },
      ],
      headersTool: [
        { text: 'No', value: 'index' },
        { text: 'Jenis Alat', value: 'toolType' },
        { text: 'Nama Alat', value: 'name_tool' },
        { text: 'Spesifikasi', value: 'specification' },
        { text: 'Kepemilikan', value: 'ownership' },
        { text: 'Jumlah Total', value: 'total' },
        { text: 'Jumlah Laik', value: 'eligible' },
      ],
      typesArray: {
        land: 'Tanah',
        building: 'Bangunan',
        room: 'Ruangan',
      },
      conditionsArray: {
        baik: 'Baik',
        'rusak ringan': 'Rusak Ringan: 1% - 30%',
        'rusak sedang': 'Rusak Sedang: 31% - 50%',
        'rusak berat': 'Rusak Berat: 50% - 100%',
      },
      typesArrayTool: {
        tool: 'Alat',
        transport: 'Angkutan',
        book: 'Buku',
      },
      certificates: [],
      certificate: {
        file: '',
      },
      titleCard: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.getSarpras()
      },
    },
    search: {
      handler() {
        this.getSarpras()
      },
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    await this.getSarpras()
  },
  methods: {
    async getSarpras(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        search: this.search,
        page: this.page,
      }).then(({ data }) => {
        this.sarprass = data.data.map((sarpras, index) => ({
          index: index + 1,
          sarprasType: this.typesArray[sarpras.type],
          sarprasCondition: this.conditionsArray[sarpras.condition],
          ...sarpras,
        }))
        this.sarprass.forEach(el => {
          this.sarpras_uuid = el.uuid
        })
        this.isLoadingTable = false
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    checkExtension(certificate) {
      const file = certificate.split('.')

      const last = file[file.length - 1]

      return last
    },
    async detailSarpras(uuid) {
      this.modalDialogSarpras = true
      this.getSarprasName(uuid)
      await this.$services.ApiServices.list('sarprastool', { sarpras_uuid: uuid }).then(({ data }) => {
        this.sarprasTools = data.data.map((sarprasTool, index) => ({
          index: index + 1,
          ...sarprasTool,
          toolType: this.typesArrayTool[sarprasTool.type_tool],
        }))
        this.sarprasTools.forEach(el => {
          this.sarpras_uuid = el.uuid
        })
        this.isLoadingTable = false
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
    },
    async getSarprasName(uuid) {
      await this.$services.ApiServices.get('sarpras', uuid).then(
        ({ data }) => {
          this.titleCard = `Data Sarpras Alat di ${data.data.name}`
        },
        err => console.error(err),
      )
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('sarpras', uuid).then(
        ({ data }) => {
          this.sarpras = data.data
          this.sarpras.photo = data.data.photo
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    closeImage() {
      this.overlay = false
      this.imageOverlay = false
      this.modalCertificate = true
    },
    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
